<template>
  <div class="err">
    <img
      src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/static/exam.png"
      @click="$router.replace({path:'/login'})"
    />
  </div>
</template>

<script>
export default {
  created(){
     localStorage.removeItem("access_token");
  }
};
</script>

<style lang="scss" scoped>
.err{
  width: 100vw;
  height: 100vh;
  background: #fff;
  text-align: center;
}
</style>